// extracted by mini-css-extract-plugin
export var black = "#060b12";
export var navy = "#0c1523";
export var paleYellow = "#fcffeb";
export var project = "Projects-module--project--fldRV";
export var project__description = "Projects-module--project__description--k8-s4";
export var project__image = "Projects-module--project__image--Rs9QS";
export var project__link = "Projects-module--project__link--CmYgC";
export var project__logo = "Projects-module--project__logo--Jx3PF";
export var project__text = "Projects-module--project__text--Qs9ED";
export var project__text__first = "Projects-module--project__text__first--JVeKv";
export var yellow = "#f2ff9a";