import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as styles from "./Projects.module.scss";

const Projects = ({ data }) => {
  let caseStudies = [];

  if (data && typeof data === `object`) {
    caseStudies = Object.keys(data).map(k => data[k]);
  }

  return (
    <section>
      <Container>
        <Row className={`justify-content-center`}>
          <Col lg={10} xl={8}>
            <Row>
              {caseStudies.length > 0
                ? caseStudies
                    .filter(({ caseStudy }) => caseStudy.data)
                    .map(({ caseStudy, size }, index) => {
                      let colsLg = 12;

                      if (size === `Medium`) {
                        colsLg = 6;
                      } else if (size === `Small`) {
                        colsLg = 3;
                      }

                      return (
                        <Col lg={colsLg} key={caseStudy.data.attributes.slug}>
                          <article className={styles.project}>
                            <a
                              className={styles.project__link}
                              href={`/case-studies/${caseStudy.data.attributes.slug}/`}
                              aria-label={`View project`}
                            >
                              {caseStudy.data.attributes.featuredImage?.data ? (
                                <div className={styles.project__image}>
                                  <img
                                    className="img-fluid"
                                    alt={
                                      caseStudy.data.attributes.featuredImage
                                        .data.attributes.alternativeText
                                    }
                                    src={
                                      caseStudy.data.attributes.featuredImage
                                        .data.attributes.url
                                    }
                                  />
                                </div>
                              ) : (
                                ``
                              )}
                              {colsLg !== 3 ? (
                                <div
                                  className={`${styles.project__text} ${
                                    index === 0
                                      ? styles.project__text__first
                                      : ``
                                  }`}
                                >
                                  {caseStudy.data.attributes.hero.clientLogo
                                    ?.data ? (
                                    <figure
                                      className={`${styles.project__logo} mb-4 mt-md-1`}
                                    >
                                      <img
                                        className="img-fluid"
                                        alt={
                                          caseStudy.data.attributes.hero
                                            .clientLogo.data.attributes
                                            .alternativeText
                                        }
                                        src={
                                          caseStudy.data.attributes.hero
                                            .clientLogo.data.attributes.url
                                        }
                                        width={120}
                                      />
                                    </figure>
                                  ) : (
                                    ``
                                  )}
                                  <p
                                    className={`${styles.project__description} mb-0`}
                                  >
                                    {caseStudy.data.attributes.description}
                                  </p>
                                </div>
                              ) : (
                                ``
                              )}
                            </a>
                          </article>
                        </Col>
                      );
                    })
                : ``}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Projects;
