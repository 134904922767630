// extracted by mini-css-extract-plugin
export var black = "#060b12";
export var imageXs = "Hero-module--image-xs--bm53d";
export var images = "Hero-module--images--H1-Pu";
export var navy = "#0c1523";
export var overlay = "Hero-module--overlay--10Sfp";
export var paleYellow = "#fcffeb";
export var section = "Hero-module--section--u4Kbz";
export var subtitle = "Hero-module--subtitle--DQicd";
export var title = "Hero-module--title--u-KCJ";
export var yellow = "#f2ff9a";