import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import * as styles from './Hero.module.scss';
import image from '../../images/case-studies-hero-mobile.png';
import image2x from '../../images/case-studies-hero-mobile@2x.png';
import imageMdLeft from '../../images/case-studies-hero-left.png';
import imageMdLeft2x from '../../images/case-studies-hero-left@2x.png';
import imageMdRight from '../../images/case-studies-hero-right.png';
import imageMdRight2x from '../../images/case-studies-hero-right@2x.png';

const Hero = ({ title, subtitle }) => {
	return (
		<section className={`${styles.section} uikit-bg-dark`}>
			<div className={styles.images}>
				<img
					src={image}
					srcSet={`${image} 1x, ${image2x}`}
					alt="Graphic"
					width={269}
					height={480}
					className={`d-md-none img-fluid ${styles.imageXs}`}
					loading="eager"
					placeholder="none"
				/>
				<img
					src={imageMdLeft}
					srcSet={`${imageMdLeft} 1x, ${imageMdLeft2x}`}
					alt="Graphic"
					width={371}
					height={480}
					className="d-none d-md-block img-fluid"
					loading="eager"
					placeholder="none"
				/>
				<img
					src={imageMdRight}
					srcSet={`${imageMdRight} 1x, ${imageMdRight2x}`}
					alt="Graphic"
					width={371}
					height={480}
					className="d-none d-md-block img-fluid"
					loading="eager"
					placeholder="none"
				/>
			</div>
			<div className={styles.overlay}>
				<Container>
					<Row className={`justify-content-center`}>
						<Col>
							<h1 className={`${styles.title} text-center mb-4`} dangerouslySetInnerHTML={{__html: title}}/>
							{subtitle ? <p className={styles.subtitle}>{subtitle}</p> : ``}
						</Col>
					</Row>
				</Container>
			</div>
		</section>
	);
};

export default Hero;
